'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

/**
 * @fileOverview Find scroll parent
 */

exports.default = function (node) {
    if (!node) {
        return document.documentElement;
    }

    var excludeStaticParent = node.style.position === 'absolute';
    var overflowRegex = /(scroll|auto)/;
    var parent = node;

    while (parent) {
        if (!parent.parentNode) {
            return node.ownerDocument || document.documentElement;
        }

        var style = window.getComputedStyle(parent);
        var position = style.position;
        var overflow = style.overflow;
        var overflowX = style['overflow-x'];
        var overflowY = style['overflow-y'];

        if (position === 'static' && excludeStaticParent) {
            parent = parent.parentNode;
            continue;
        }

        if (overflowRegex.test(overflow) && overflowRegex.test(overflowX) && overflowRegex.test(overflowY)) {
            return parent;
        }

        parent = parent.parentNode;
    }

    return node.ownerDocument || node.documentElement || document.documentElement;
};