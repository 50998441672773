export const leftData = [
    'IBDP',
    'MYP',
    'Alevel',
    '美高',
    'AP',
    'IGCSE',
    'GCSE',
    'AMC',
    'SAT',
    'IELTS',
    'TOEFL',
    '小托福',
    '国际竞赛'
]

export const rightData = [
    {img:'side1',txt:'入学指南'},
    {img:'side2',txt:'入学条件'},
    {img:'side3',txt:'招生时间'},
    {img:'side4',txt:'入学费用'},
    {img:'side5',txt:'入学测试'},
    {img:'side6',txt:'校园环境'},
    {img:'side7',txt:'升学率'},
    {img:'side8',txt:'学校探校日'}
]