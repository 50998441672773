export const content = [
  {
    img: 'one',
    text1: '试学不满意随时退',
    text2: '郑重承诺一'
  },
  {
    img: 'two',
    text1: '24小时在线陪伴学习',
    text2: '郑重承诺二'
  },
  {
    img: 'three',
    text1: '全职讲师',
    text2: '郑重承诺三'
  }
]

export const navList = [
  {
    name: 'A-Level课程',
    url: '/alevel'
  },
  {
    name: 'IGCSE课程',
    url: '/igcse'
  },
  {
    name: 'IB课程',
    url: '/ib'
  },
  {
    name: 'AP课程',
    url: '/ap'
  },
  {
    name: '国际学校备考',
    url: '/mentor'
  },
  {
    name: '托福',
    url: '/toefl-course'
  },
  {
    name: '雅思',
    url: '/ielts'
  },
  {
    name: '国际双语竞赛',
    url: '/amc'
  }
]