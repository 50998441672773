export const content = [
  {
    label: '首页',
    value: '/',
    children: []
  },
  {
    label: '国际学校备考',
    value: '/mentor',
    img: 'hot',
    children: []
  },
  {
    label: 'A Level',
    value: '/alevel',
    children: []
  },
  {
    label: 'IB',
    value: '/ib',
    children: []
  },
  {
    label: 'AP',
    value: '/ap',
    children: []
  },
  {
    label: 'IGCSE',
    value: '/igcse',
    children: []
  },
  // {
  //     label: '小托福',
  //     value: '/toefl',
  //     children: []
  // },
  {
    label: '出国语言',
    value: '/',
    children: [
      {
        text: '托福课程',
        url: '/toefl-course',
        num: 20
      },
      {
        text: '雅思课程',
        url: '/ielts',
        num: 21
      },
      {
        text: 'SAT课程',
        url: '/sat',
        num: 22
      }
    ]
  },
  // {
  //     label: '国际竞赛',
  //     value: '/',
  //     children: [
  //         {
  //             text:'AMC8/10/12',
  //             url:'/amc',
  //             num:31
  //         },
  //         // {
  //         //     text: 'NEC',
  //         //     url: '#',
  //         //     num:32
  //         // },
  //     ]
  // },
  // {
  //     label: '师资团队',
  //     value: '/teacher',
  //     children: []
  // },
  // {
  //     label: '学员故事',
  //     value: '/story',
  //     children: []
  // },
  {
    label: '关于菠萝',
    value: '/about',
    children: []
  }
]