import React from 'react';
import './index.scss';
import { getImage, openChat, getHost } from '@/function';
import { leftData, rightData } from './data';
class Side extends React.Component {
    constructor(){
        super()
        this.url = 'website/pc/exhibit';
        this.image = getImage(this.url, '.png');
        this.state = {
            PopupVisible: 'block',
            type:''
        }    
    }
    componentWillMount(){
        if(getHost()){
            // www域名是赵天赐的页面
            this.setState({
                type:1
            })
        }else{
            // edu域名是徐恒豪的页面
            this.setState({
                type:2
            })
        }
    }  
    render(){
        return(
            <section className="sides web-font">
                <div className="left" style={{ backgroundImage: `url(${this.image('side')})`, backgroundSize: '100% 100%' }}>
                    <div className="list">
                        {
                            leftData.map((item,index)=>(
                                <div onClick={openChat} className="item" key={index} style={{ backgroundImage: `url(${this.image('border')})`, backgroundSize: '100% 100%' }}>
                                    <p>{item}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className={this.state.type == 1? 'right hide' : 'right'}>
                    {
                        rightData.map((item,index)=>(
                            <div onClick={openChat} className="item" key={index}>
                                <img alt="" src={this.image(`${item.img}`)} />
                                <p>{item.txt}</p>
                            </div>
                        ))
                    }
                </div>
            </section>
        )
    }
}

export default Side;