import React from 'react';
import './index.scss';
import { getImage } from '@/function';
import { Link } from "react-router-dom";
import { content } from './data';
import address from '@/utils/address';
import Poput from '@/components/pc/editionOne/popup';
class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      scrollTop: 0,
      headerBg: '#FAFAFC',
      textColor: '#fff',
      buttonBg: '#fff',
      buttonColor: '#FF526D',
      studentBtnBg: '#fff',
      studentBtnColor: '#1890ff',
      boxShow: 'none',
      isHeaderTop: true,
      activeIndex: undefined,
      active2Index: undefined,
      buryPoint: '',
      formTitleType: ''
    }
    this.url = 'website/pc/homes';
    this.image = getImage(this.url, '.png');
    this.popRef = React.createRef();
  }

  componentDidMount() {
    let pathname = window.location.pathname
    if (pathname === '/') {
      this.setState({
        activeIndex: 0,
        active2Index: undefined
      })
    } else if (pathname === '/mentor') {
      this.setState({
        activeIndex: 1,
        active2Index: undefined
      })
    } else if (pathname === '/alevel') {
      this.setState({
        activeIndex: 2
      })
    } else if (pathname === '/ib') {
      this.setState({
        activeIndex: 3
      })
    } else if (pathname === '/ap') {
      this.setState({
        activeIndex: 4
      })
    } else if (pathname === '/igcse') {
      this.setState({
        activeIndex: 5,
        active2Index: 23
      })
    } else if (pathname === '/toefl') {
      this.setState({
        activeIndex: 6,
        active2Index: 30
      })
    } else if (pathname === '/toefl-course') {
      this.setState({
        activeIndex: 7,
        active2Index: 20
      })
    } else if (pathname === '/ielts') {
      this.setState({
        activeIndex: 7,
        active2Index: 21
      })
    } else if (pathname === '/amc') {
      this.setState({
        activeIndex: 8,
        active2Index: 31
      })
    } else if (pathname === '/teacher') {
      this.setState({
        activeIndex: 400,
        active2Index: undefined
      })
    } else if (pathname === '/story') {
      this.setState({
        activeIndex: 5,
        active2Index: undefined
      })
    } else if (pathname === '/about') {
      this.setState({
        activeIndex: 9,
        active2Index: undefined
      })
    } else if (pathname === '/nec') {
      this.setState({
        activeIndex: 9,
        active2Index: 32
      })
    } else if (pathname === '/tuofupeiyou') {
      this.setState({
        activeIndex: 9,
        active2Index: 20
      })
    } else if (pathname === '/yasipeiyou') {
      this.setState({
        activeIndex: 9,
        active2Index: 21
      })
    } else if (pathname === '/sat') {
      this.setState({
        activeIndex: 7,
        active2Index: 22
      })
    }
    let bannerHeight = window.innerWidth * 740 / 1920
    let that = this
    window.addEventListener('scroll', function () {
      that.getScrollTop()
      let { scrollTop } = that.state
      if (scrollTop > bannerHeight - 60) {
        that.setState({
          headerBg: '#FAFAFC',
          textColor: '#333',
          buttonBg: '#FF526D',
          buttonColor: '#fff',
          studentBtnBg: '#1890ff',
          studentBtnColor: '#fff',
          boxShow: '0px 2px 8px 0px rgba(0,0,0,0.1)',
          isHeaderTop: false
        })
      } else {
        that.setState({
          headerBg: '#FAFAFC',
          textColor: '#fff',
          buttonBg: '#fff',
          buttonColor: '#FF526D',
          studentBtnBg: '#fff',
          studentBtnColor: '#1890ff',
          boxShow: 'none',
          isHeaderTop: true
        })
      }
    }, false)
  }
  getScrollTop() {
    if (document.documentElement && document.documentElement.scrollTop) {
      this.setState({
        scrollTop: document.documentElement.scrollTop
      })
    } else if (document.body) {
      this.setState({
        scrollTop: document.body.scrollTop
      })
    }
  }
  isActive(index, isMore) {
    localStorage.setItem('activeIndex', index)
    !isMore && localStorage.removeItem('active2Index')
  }
  isActive2(index) {
    localStorage.setItem('active2Index', index)
  }
  openDialog = (buryPoint, formTitleType) => {
    this.setState({
      buryPoint,
      formTitleType
    }, function () {
      this.popRef.current.openDialog()
    })
  }
  render() {
    const { headerBg, boxShow, isHeaderTop, textColor, buttonBg, buttonColor, studentBtnBg, studentBtnColor,
      activeIndex, active2Index, scrollTop } = this.state
    return (
      <section className="header" style={{ background: headerBg, boxShadow: boxShow }}>
        <div className="header-inner">
          <div className="inside">
            {
              isHeaderTop ?
                <img alt="菠萝在线logo" src={this.image('logo2')} />
                :
                <img alt="菠萝在线logo" src={this.image('logo2')} />
            }

            <div className="header-btn">
              <button><a href={address.SERVER_STUDENT_ADDRESS}>学生端入口</a></button>
              <button onClick={this.openDialog.bind(this, this.props.buryPoint1, 1)}>预约1v1教师答疑课</button>
              <button onClick={this.openDialog.bind(this, this.props.buryPoint2, 2)}>预约线上测评模考</button>
            </div>
          </div>
        </div>
        <div className="navlist">
          <ul style={{ color: textColor }}>
            {
              content.map((item, index) => {
                const { label, value, children } = item;
                return (
                  children.length ?
                    <li className={`${activeIndex === index && 'active'} ${activeIndex === index && scrollTop > 600 && 'active2'}`} key={index} onClick={this.isActive.bind(this, index, true)}>
                      {label}
                      <img src={this.image('down')} alt="" />
                      <span className="line"></span>
                      <div>
                        {
                          children.map((item, index) => (
                            <p key={index} className={`${active2Index === item.num && 'active'}`} onClick={this.isActive2.bind(this, index)}>
                              <Link to={item.url}>{item.text}</Link>

                            </p>
                          ))
                        }
                      </div>

                    </li>
                    :
                    <li key={index} className={`${activeIndex === index && 'active'} ${activeIndex === index && scrollTop > 600 && 'active2'}`} onClick={this.isActive.bind(this, index, false)}>
                      <Link to={value}>{label}</Link>
                      <span className="line"></span>
                    </li>
                )
              })
            }
          </ul>
        </div>

        <Poput
          ref={this.popRef}
          actKey={this.props.actKey}
          buryPoint={this.state.buryPoint}
          formTitleType={this.state.formTitleType}
        />
      </section>
    )
  }
}

export default Header;