import React from 'react';
import './index.scss';
import { getImage, getHost } from '@/function';
import { content, navList } from './data';
import { Link } from "react-router-dom";
class Bottom extends React.Component {
  constructor() {
    super()
    this.state = {
      scrollTop: 0,
      headerBg: 'transparent',
      phone: '',
      type: null
    }
    this.url = 'website/pc/homes';
    this.image = getImage(this.url, '.png');
  }
  componentWillMount() {
    if (getHost()) {
      // www域名是赵天赐的页面
      this.setState({
        phone: '195-1226-4689',
        type: 1
      })
    } else {
      // edu域名是徐恒豪的页面
      this.setState({
        phone: '195-1226-4689',
        type: 2
      })
    }
  }
  render() {
    return (
      <section className="bottom-pc bottom-pc-tl">
        <div className="bottom-top" style={{ background: `url(${this.image('bottomBg')})`, backgroundSize: '100% 100%' }}>
          <div className="container">
            <div className="title">
              <img src={this.image('1')} alt="" />
              <div className="bottom-title">菠萝在线三大承诺</div>
              <img src={this.image('1')} alt="" />
            </div>
            <div className="content">
              {
                content.map((item, index) => (
                  <div className="block" key={index}>
                    <div className="top">
                      <img alt="" src={this.image(`${item.img}`)} />
                      <p className="">{item.text2}</p>
                    </div>
                    <p className="show">{item.text1}<img src={this.image('p_line')} /></p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="bottom-middle">
          <div className="container">
            <div className="m_left">
              <img src={this.image('logo1')} alt="菠萝在线logo" />
              <div className="tel">
                <img src={this.image('tel')} alt="" />
                <span>联系电话</span>
              </div>
              <p>{this.state.phone}</p>
            </div>
            <div className="m_middle">
              {
                navList.map((item, index) => (
                  <p key={index}>
                    <Link to={item.url}>{item.name}</Link>
                    <img src={this.image('b_line')} />
                  </p>
                ))
              }
              <img src={this.image('bot_line')} alt="" />
            </div>
            <div className="m_right">
              {
                this.state.type == 1
                  ?
                  <img src={this.image('wx2')} alt="课程顾问老师二维码" />
                  :
                  <img src={this.image('wx')} alt="课程顾问老师二维码" />
              }
            </div>
          </div>
        </div>
        <div className="bottom-btm">
          <div className="container">
            <p>
              <Link to="/">首页</Link>
              <Link to="/about">关于我们</Link>
              <Link to="/privacy">隐私协议</Link>
              {/* <Link to="/chrome">下载中心</Link> */}
              <Link to="/operation">操作指南</Link>
            </p>
            <p>
              <img alt="" src={this.image('police')} 
                 onClick={() => {
                 window.open("https://beian.miit.gov.cn", "_blank")
               }} />
            <a  className="beianhao" href="https://beian.miit.gov.cn" target={"_blank"}>沪公网安备 31010602004508</a>
            <span className="line"></span>
            <a  className="beianhao" href="https://beian.miit.gov.cn" target={"_blank"}>沪ICP备 18033546号 ICP认证：沪B2-20200683</a>
            </p>
          </div>
        </div>
      </section>
    )
  }


}

export default Bottom;