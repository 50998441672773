import React from 'react';
import './index.scss';
import http from '../../../../utils/reauest';
import { message} from 'antd';
import { getBrowserSource } from '../../../../function'
import Activity from "../../../../bundle/activity";
class Form extends React.Component {
    constructor(){
        super()
        this.browserSource = getBrowserSource();
        this.state = {
            
        }
        this.listNameRef = React.createRef();
        this.listPhoneRef = React.createRef();
    }
    
    componentDidMount() {
        
    }

    // 提交表单
    submit = () => {
        let listName = this.listNameRef.current.value
        let listPhone = this.listPhoneRef.current.value
        let hiddenRemark = this.props.btnText
        let myreg=/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0135678]|19[89]|18[0-9]|14[57])[0-9]{8}$/;

        if (!myreg.test(listPhone)) {
            message.error('请输入正确的手机号码！')
        }else{
            let { actKey } = this.props
            let activityId = Activity[actKey].activityId;
            const params = {
                listName,
                listPhone,
                hiddenRemark,
                activityId,
                attendGrade: '',
                srcPage:this.browserSource,
                remark:''
            }
            http.post('submitForm', params).then(res => {
                let msg = res.message
                message.success(msg)
                this.listNameRef.current.value = ''
                this.listPhoneRef.current.value = ''
            }).catch(err => {
                // message.error(err.message)
            })
        }
    }
    onInput = () => {
        let value = this.listPhoneRef.current.value
        if(value.length>11){
            this.listPhoneRef.current.value=value.slice(0,11)
        } 
    }
   
    render(){
        return(
            <section className="Form">
                <div className="form">
                    <div className="inputItem">
                        <span>姓名：</span>
                        <input type="text" ref={this.listNameRef} />
                    </div>
                    <div className="inputItem">
                        <span>电话：</span>
                        <input onInput={this.onInput} ref={this.listPhoneRef} type="number"/>
                    </div>
                    <button onClick={this.submit}>{this.props.btnText}</button>
                </div>
            </section>
        )
    }
}

export default Form;