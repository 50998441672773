import axios from 'axios'
import { getApi } from './api'
import address from './address';
import {message} from 'antd';
const $message = message;
const service = axios.create({
    baseURL: address.SERVER_MAIN_ADDRESS,
    timeout:30000,
    headers:{'Content-Type':'application/x-www-form-urlencoded'}
})

// 请求拦截器
service.interceptors.request.use( 
    config => {
        return config
    },
    err => {
        // 对请求错误做些什么
        return Promise.reject(err);
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const { data:{code,message}, data} = response;
        if(code === 1){
            return Promise.resolve(data);
        }else{
            $message.error(message);
            return Promise.reject(message);
        }
    },
    error => {
        //提示框
        $message.error(error);
        return Promise.reject(error)
    }
)

// get请求
const get = async (api,params,config={},headers={})=>{
    try{
        return await service({
            url: getApi(api),
            params,
            headers,
            ...config
        }) 
    }
    catch(error){
        throw error;
        return null;
    }
}

// post请求
const post = async (api,data,config={},headers={})=>{
    let params = data
    try{
        return await service({
            url: getApi(api),
            method:'post',
            params,
            headers,
            ...config
        })
    }
    catch(error){
        throw error;
        return null;
    }
}

export default {
    get,
    post
}