import React from 'react';
import { Helmet } from 'react-helmet';
import { Carousel } from 'antd';
import CountUp from 'react-countup';
import { Link } from "react-router-dom";

import { getImage, openChat } from '@/function';

import './index.scss';
import { bannerImg, oneData, twoData, threeData, fourData, fiveData, offerData } from './data'

import Header from '@/components/pc/editionOne/header';
import Bottom from '@/components/pc/editionOne/bottom';
import Poput from '@/components/pc/editionOne/popup';
import Form from '@/components/pc/editionThree/Form';
import Sides from '@/components/pc/exhibition/side';

import "@/animate.css";
import ReactWOW from "@/utils/wow";

const ArrowSvg = () => (
  <svg width="31px" height="14px" viewBox="0 0 31 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g transform="translate(-945.000000, -2229.000000)" stroke="currentColor" strokeWidth="2">
        <g transform="translate(946.000000, 2230.000000)">
          <path d="M7,0 L0.885799369,5.2407434 C0.466473527,5.60016555 0.41791199,6.23146553 0.777334141,6.65079137 C0.810658833,6.68967018 0.846920562,6.72593191 0.885799369,6.7592566 L7,12" id="路径-3" transform="translate(3.500000, 6.000000) scale(-1, 1) translate(-3.500000, -6.000000) "></path>
          <path d="M18,0 L11.8857994,5.2407434 C11.4664735,5.60016555 11.417912,6.23146553 11.7773341,6.65079137 C11.8106588,6.68967018 11.8469206,6.72593191 11.8857994,6.7592566 L18,12" id="路径-3备份" transform="translate(14.500000, 6.000000) scale(-1, 1) translate(-14.500000, -6.000000) "></path>
          <path d="M29,0 L22.8857994,5.2407434 C22.4664735,5.60016555 22.417912,6.23146553 22.7773341,6.65079137 C22.8106588,6.68967018 22.8469206,6.72593191 22.8857994,6.7592566 L29,12" id="路径-3备份-2" transform="translate(25.500000, 6.000000) scale(-1, 1) translate(-25.500000, -6.000000) "></path>
        </g>
      </g>
    </g>
  </svg>
);
class Home extends React.Component {
  constructor() {
    super()
    this.url = 'website/pc/homes';
    this.image = getImage(this.url, '.png');
    this.popRef = React.createRef();
    this.state = {
      buryPoint: '',
      formTitleType: '',
      navIndex: 0,
      leftOver: false,
      rightOver: false,
      leftOver1: false,
      rightOver1: false,
      leftOver2: false,
      rightOver2: false
    }
  }

  componentDidMount() {

  }

  changeNav(e) {
    this.setState({
      navIndex: e,
    }, function () {
      console.log(this.state.navIndex)
    })
  }

  carouselPrev = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.slider.slick.slickPrev()

  }
  carouselNext = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.slider.slick.slickNext()
  }
  carouselPrev1 = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.banner.slick.slickPrev()

  }
  carouselNext1 = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.banner.slick.slickNext()
  }
  leftOver(e) {
    this.setState({
      leftOver: true
    })
  }
  leftOut(e) {
    this.setState({
      leftOver: false
    })
  }
  rightOver(e) {
    this.setState({
      rightOver: true
    })
  }
  rightOut(e) {
    this.setState({
      rightOver: false
    })
  }
  openDialog = (buryPoint, formTitleType) => {
    this.setState({
      buryPoint,
      formTitleType
    }, function () {
      this.popRef.current.openDialog()
    })
  }

  leftOver1(e) {
    this.setState({
      leftOver1: true
    })
  }
  leftOut1(e) {
    this.setState({
      leftOver1: false
    })
  }
  rightOver1(e) {
    this.setState({
      rightOver1: true
    })
  }
  rightOut1(e) {
    this.setState({
      rightOver1: false
    })
  }

  bannerSiteOver(event) {
    var x = event.clientX;    //鼠标所在元素的X轴坐标
    var w = document.body.clientWidth;  //页面可视区域宽度
    if (x < w / 2) {
      this.setState({
        leftOver2: true,
        rightOver2: false
      })
    } else {
      this.setState({
        rightOver2: true,
        leftOver2: false
      })
    }
  }

  bannerSiteOut(event) {
    this.setState({
      leftOver2: false,
      rightOver2: false
    })
  }
  Banner() {
    return (
      <section className="banner" id="banner" onMouseMove={this.bannerSiteOver.bind(this)} onMouseOut={this.bannerSiteOut.bind(this)}>
        <Carousel autoplay="true" ref={el => (this.banner = el)}>
          {
            bannerImg.map((item, index) => (
              <div className="banner-inner" key={index}>
                {
                  item.btn ?
                    <div className="banner-box" key={index} onClick={(e) => {
                      e.preventDefault()
                      window.open("https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1", "_self");
                    }}>
                      <img alt="" src={this.image(`${item.img}`)} />
                      {/* <a onClick={this.openDialog.bind(this,item.buryPointText1,5)}></a>
                                            <a onClick={this.openDialog.bind(this,item.buryPointText2,6)}></a> */}
                    </div>
                    :
                    // item.url ?
                    item.url == 'https://www.boluozaixian.cn/caseDetails?id=15' ? <a href="https://www.boluozaixian.cn/caseDetails?id=15" target="_blank"><img alt="" src={this.image(`${item.img}`)} /></a> :
                      // <Link to={item.url} target="_blank"><img alt="" src={this.image(`${item.img}`)} /></Link>

                      // <a href={"https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1"} target="_self">
                      //   <img alt="" src={this.image(`${item.img}`)} />
                      // </a>
                      // <img alt="" src={this.image(`${item.img}`)}/>
                      // :
                      // <div key={index} onClick={this.openDialog.bind(this,item.buryPointText,1)}>
                      <div key={index} onClick={(e) => {
                        e.preventDefault()
                        window.open("https://tb.53kf.com/code/client/b5bb9bd67eb5b25444fbaa74f871e1009/1", "_self");
                      }}>
                        <img alt="" src={this.image(`${item.img}`)} />
                      </div>
                }
              </div>
            ))
          }
        </Carousel>

        <div className="arrow_left" onClick={openChat}>
          <img onClick={this.carouselPrev1} style={{ display: this.state.leftOver1 ? "block" : "none" }} src={this.image("left01")} alt="" />
          <img onClick={this.carouselPrev1} style={{ display: this.state.leftOver2 ? "block" : "none" }} onMouseOver={this.leftOver1.bind(this)} onMouseOut={this.leftOut1.bind(this)} src={this.image("left02")} alt="" />
        </div>
        <div className="arrow_right" onClick={openChat}>
          <img onClick={this.carouselNext1} style={{ display: this.state.rightOver1 ? "block" : "none" }} src={this.image("right01")} alt="" />
          <img onClick={this.carouselNext1} style={{ display: this.state.rightOver2 ? "block" : "none" }} onMouseOver={this.rightOver1.bind(this)} onMouseOut={this.rightOut1.bind(this)} src={this.image("right02")} alt="" />
        </div>
      </section>
    )
  }
  One() {
    return (
      <section className="oneOut" ref="one">
        <div className="one" style={{ backgroundImage: `url(${this.image('Background')})`, backgroundSize: '1920px 100%' }}>
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <div className="title" style={{ opacity: this.state.opacity1, top: this.state.top }}>
              <img src={this.image('embellishs')} alt="" />
              <div>菠萝在线金牌课程全阶段覆盖</div>
              <img src={this.image('embellishs')} alt="" />
              <p>家长、学生高口碑认可</p>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <div>
              <div className="nav" style={{ opacity: this.state.opacity1, top: this.state.top }}>
                {
                  oneData.map((item, index) => (

                    <div key={index}>
                      <div className='item'>
                        <img className={`${index === this.state.navIndex ? "selected" : null}`} src={this.image(`${index === this.state.navIndex ? `${item.navbg1}` : `${item.navbg}`}`)} style={{ zIndex: `${10 - index}`, }} alt="" />
                        <p onClick={this.changeNav.bind(this, index)} key={index}>{item.type}</p>
                      </div>
                      <div className={`bot_line ${index === this.state.navIndex ? "bot_lines" : null}`}>
                        <img alt="" src={this.image(`${item.bot}`)} />
                      </div>
                    </div>

                  ))
                }

              </div>

              <div className="content" style={{ opacity: this.state.opacity1, top: this.state.top }}>
                <Link to={oneData[this.state.navIndex].url} target="_blank">
                  <div className="list">
                    <div className={`left ${this.state.navIndex == 4 || this.state.navIndex == 5 || this.state.navIndex == 6 ? "hasLeft" : null}`}>
                      <div className="l_top">
                        <img src={this.image('embellish')} alt="" />
                        <p>热门科目</p>
                        <img src={this.image('embellish')} alt="" />
                      </div>
                      <div className="subject">
                        {
                          oneData[this.state.navIndex].subjectList.map((item, index) => (

                            <div key={index} className={`${this.state.navIndex == 2 ? "ibOutStyle" : null}`}>
                              <span className={`s_item ${this.state.navIndex == 2 ? "ibStyle" : null}`} key={index}>{item}<span className="line"></span></span>

                            </div>

                          ))
                        }
                      </div>
                    </div>
                    <div className={`right ${this.state.navIndex == 4 || this.state.navIndex == 5 || this.state.navIndex == 6 ? "full" : null} ${this.state.navIndex == 5 || this.state.navIndex == 6 ? "hasLeft" : null}`}>
                      {
                        oneData[this.state.navIndex].content.map((item, index) => (
                          <div className={`r_item ${this.state.navIndex == 5 || this.state.navIndex == 6 ? "hasLeft" : null} ${this.state.navIndex == 7 ? "jsStyle" : null}`} key={index}>
                            <p className={`${this.state.navIndex == 4 || this.state.navIndex == 0 || this.state.navIndex == 7 ? "bkStyle" : null}`}>{item.title}</p>
                            <p className={`maintitle ${this.state.navIndex == 0 || this.state.navIndex == 7 ? "bkheadStyle" : null}`}>{item.maintitle}</p>
                            <span className={`headline ${this.state.navIndex == 4 ? "bkheadStyle" : null}`}>{item.oneTitle}</span>
                            <span className="datas">{item.oneData}</span><br />
                            <span className="headline">{item.twoTitle}</span>
                            <span className="datas">{item.twoData}</span>
                          </div>
                        ))
                      }
                    </div>
                    <div className={`${this.state.navIndex == 5 || this.state.navIndex == 6 ? 'tfys' : "hasLeft"}`}>
                      {
                        oneData[this.state.navIndex].content.map((item, index) => (
                          <div className="tfys_item" key={index}>
                            <p>{item.title}</p>
                            <span className="headline">课程班型：</span>
                            <span className="datas">{item.oneData}</span><br />
                            <span className="headline">开班时间：</span>
                            <span className="datas">{item.twoData}</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Link>
              </div>

            </div>
          </ReactWOW>

          <ReactWOW animation="fadeInUp" delay="0.2s">
            <div className="btn" style={{ opacity: this.state.opacity1, top: this.state.top }}>
              <button onClick={openChat}>点击了解课程详细信息</button>
              <button onClick={openChat}>预约1v1教师答疑课</button>
            </div>
          </ReactWOW>

        </div>

      </section>
    )
  }
  Two() {
    return (
      <section className="two" ref="two" style={{ opacity: this.state.opacity2, top: this.state.top2 }}>
        <div className="">
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <div className="title">
              <img src={this.image('embellish')} alt="" />
              <div>菠萝在线专注国际课程，全学习段服务</div>
              <img src={this.image('embellish')} alt="" />
              <p>覆盖学生全学习段课程</p>
            </div>
          </ReactWOW>

          <div className="content">
            <div className="road" style={{ backgroundImage: `url(${this.image('road')})`, backgroundSize: '1920px 100%' }}>
              <div style={{ width: '1200px', margin: '0 auto' }}>
                <img src={this.image('right1')} alt="" />
                <img src={this.image('right2')} alt="" />
              </div>
            </div>
            <div className="container">
              <div className="list">
                {
                  twoData.map((item, index) => (
                    <ReactWOW key={index} animation="fadeInUp" delay={-0.1 + index / 3 + "s"}>
                      <div className="item" style={{ backgroundImage: `url(${this.image(`${item.out}`)})`, backgroundSize: '100% 100%' }}>
                        <img className="img" src={this.image(`${item.img}`)} />
                        <p className="txt1">{item.txt2}</p>
                        <p className="txt2">{item.txt1}</p>
                        <img className="num" src={this.image(`${item.num}`)} alt="" />
                        <div className="show" style={{ backgroundImage: `url(${this.image(`${item.bg}`)})`, backgroundSize: '100% 100%' }}>
                          <p>{item.txt3}</p>
                          <p>菠萝在线为您提供：</p>
                          <p>{item.txt4}</p>
                          <p>{item.txt5}</p>
                          <p>{item.txt6}</p>
                        </div>
                      </div>
                    </ReactWOW>
                  ))
                }
              </div>
              <div className="btn">
                <button onClick={openChat}>升学规划咨询</button>
                <button onClick={openChat}>同步课程辅导</button>
                <button onClick={openChat}>冲刺名校秘籍</button>
              </div>
            </div>
          </div>

        </div>
      </section>
    )
  }
  Three() {
    return (
      <section className="threeOut" ref="three">
        <div className="three" style={{ backgroundImage: `url(${this.image('offerBg')})`, backgroundSize: '1920px 100%', opacity: this.state.opacity3, top: this.state.top3 }}>
          <div className="container">
            <ReactWOW animation="fadeInUp" delay="0.2s">
              <div className="title">
                <img src={this.image('embellish')} alt="" />
                <div>菠萝在线屡创佳绩，成果斐然</div>
                <img src={this.image('embellish')} alt="" />
              </div>
              <p className="show" style={{ backgroundImage: `url(${this.image('showbg')})`, backgroundSize: '100% 100%' }}>国际学校录取成果展示（部分offer）</p>
            </ReactWOW>

            <div className="content">
              {
                threeData.map((item, index) => (
                  <ReactWOW key={index} animation="fadeInUp" delay={0.1 + index / 8 + "s"}>
                    <div className="item">
                      <img src={this.image(`${item.img}`)} alt="" />
                      <p>{item.name}</p>
                      <p><CountUp end={Number(item.number)} /><span style={{ fontSize: '22px' }}>枚</span></p>
                    </div>
                  </ReactWOW>
                ))
              }
            </div>
            <ReactWOW animation="fadeInUp" delay="0.2s">
              <div className="changeList">
                <Carousel autoplay dots={false} ref={el => (this.slider = el)}>
                  {
                    offerData.map((item, index) => (
                      <div className="imgList" key={index}>
                        {
                          item.map((item1, index1) => (
                            <div className="changeItem" key={index1}>
                              <img alt="" src={this.image(`${item1}`)} />
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </Carousel>

                <div onMouseOver={this.leftOver.bind(this)} onMouseOut={this.leftOut.bind(this)} style={{ display: this.state.leftOver ? "none" : "block" }}>
                  <img className="change prev" onClick={this.carouselPrev} alt="" src={this.image('ls_btn')} />
                </div>
                <div onMouseOver={this.rightOver.bind(this)} onMouseOut={this.rightOut.bind(this)} style={{ display: this.state.rightOver ? "none" : "block" }}>
                  <img className="change next" onClick={this.carouselNext} alt="" src={this.image('rs_btn')} />
                </div>

                <div onMouseOver={this.leftOver.bind(this)} onMouseOut={this.leftOut.bind(this)} style={{ display: this.state.leftOver ? "block" : "none" }}>
                  <img className="change prev" onClick={this.carouselPrev} alt="" src={this.image('l_bth')} />
                </div>
                <div onMouseOver={this.rightOver.bind(this)} onMouseOut={this.rightOut.bind(this)} style={{ display: this.state.rightOver ? "block" : "none" }}>
                  <img className="change next" onClick={this.carouselNext} alt="" src={this.image('r_btn')} />
                </div>

              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
    )
  }
  Four() {
    return (
      <section className="four" ref="four" style={{ opacity: this.state.opacity4, top: this.state.top4 }}>
        <div className="container">
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <div className="title">
              <img src={this.image('embellish')} alt="" />
              <div>菠萝在线师资团队</div>
              <img src={this.image('embellish')} alt="" />
              <p>全职资深老师授课</p>
              <p>国际课程亲身学习经历/专业的教研与教学沉淀</p>
            </div>
          </ReactWOW>
          <div className="content">
            {
              fourData.map((item, index) => (
                <ReactWOW key={index} animation="fadeInUp" delay={0.1 + index / 16 + "s"}>
                  <div className="item">
                    <img src={this.image(`${item.img}`)} alt="" />
                    <div className="bottom bot">
                      <p>{item.name}</p>
                      <p>{item.school}</p>
                    </div>

                    <div className="info bot">
                      <p>{item.name}</p>
                      <p>{item.school}</p>
                      {
                        item.details.map((item, index) => (
                          <p className="details" key={index}>{item}</p>
                        ))
                      }
                      <p className="result">{item.result}</p>
                    </div>
                  </div>
                </ReactWOW>
              ))
            }
          </div>
          <div className="btn">
            <button><Link to='/teacher' target="_blank">点击查看更多老师介绍</Link></button>
            <button onClick={openChat}>预约1v1教师答疑课</button>
          </div>
        </div>
      </section>
    )
  }
  Five() {
    return (
      <section className="five" ref="five" style={{ backgroundImage: `url(${this.image('togetherbg')})`, backgroundSize: '100% 100%', opacity: this.state.opacity5, top: this.state.top5 }}>
        <div className="container">
          <ReactWOW animation="fadeInUp" delay="0.2s">
            <div className="title">
              <div>荣誉源于实力，广受社会认可</div>
              <div className='titleText'>国际课程辅导机构那么多，为什么要选择菠萝在线国际教育</div>
            </div>
          </ReactWOW>
          <div className="content">
            {
              fiveData.map((item, index) => (
                <ReactWOW key={index} animation="fadeInUp" delay={0.1 + index / 8 + "s"}>
                  {/* <div className="item" >
                    <img src={this.image(`${item.img}`)} alt="" />
                    <div className="info">
                      <p>{item.txt1}</p>
                      <p>{item.txt2}</p>
                    </div>
                  </div> */}
                  <div className='item'>
                    <div className='imgBox'>
                    <img className='infoImg' src={this.image(`${item.img}`)} alt="" />
                    </div>
                    <div className='infoText'>{item.text}</div>
                  </div>
                </ReactWOW>
              ))
            }
          </div>
          <div className="btn">
            <button onClick={openChat}>问我想问</button>
            <button onClick={openChat}>预约课程</button>
          </div>
        </div>
      </section>
    )
  }
  render() {
    return (
      <section className="Homepc">
        <h1 className="h1Title">国际学校备考</h1>
        <Helmet>
          <meta charSet="utf-8" />
          <title>菠萝在线专注国际学校备考_alevel_ib_ap_igcse_托福_雅思等课程一对一-班课培训辅导</title>
          <meta name="keywords" Content="国际学校备考,alevel,ib,ap,igcse,托福,雅思,课程,一对一,班课,培训,辅导" />
          <meta name="description" Content="菠萝在线作为沪上知名国际课程在线辅导机构，主要有AP培训、Alevel培训、IB培训等，择校备考，同步辅导等业务。菠萝在线致力于用线上真人一对一的辅导模式，帮助每一个家庭抓住理想的入学机会，帮助每一个孩子提升自我能力。" />
        </Helmet>
        <Header
          actKey='Homes'
          buryPoint1='首页-在线试听'
          buryPoint2='首页-资料领取'
        />
        {this.Banner()}
        <Form
          btnText='预约线上测评模考'
          actKey='Homes'
        />
        {this.One()}
        <Form
          btnText='预约1v1教师答疑课'
          actKey='Homes'
        />
        {this.Two()}
        {/* {this.Three()} */}
        {this.Four()}
        {this.Five()}
        <Bottom />
        <Poput
          ref={this.popRef}
          actKey='Homes'
          buryPoint={this.state.buryPoint}
          formTitleType={this.state.formTitleType}
        />
        {/* <Popups /> */}
        <Sides />
      </section>
    )
  }
}

export default Home;

{/* <img alt="" src={this.image('arrow')} />   
    <img alt="" src={this.image(`${item.img}`)} /> */}