import React from 'react';
import './index.scss';
import { getBrowserSource, getImage } from '../../../../function'
import http from '../../../../utils/reauest';
import Activity from "../../../../bundle/activity";
import { message, Spin, Form, Input, Button } from 'antd';

class Poput extends React.Component {
  constructor() {
    super()
    this.browserSource = getBrowserSource();
    this.url = 'website/pc/homes';
    this.image = getImage(this.url, '.png');
    this.state = {
      dialogVisible: 'none',
      tipsShow: 'none',
      spinning: false,
      mainTitle: '',
      subTitle: '',
      btnText: ''
    }
    this.Images = {};
    this.listNameRef = React.createRef();
    this.listPhoneRef = React.createRef();
    this.attendGradeRef = React.createRef();
    this.remarkRef = React.createRef();
  }
  // 打开表单
  openDialog = () => {
    // console.log(this.props.formTitleType)
    let formTitleType = this.props.formTitleType
    if (formTitleType == 1) {
      this.setState({
        mainTitle: '菠萝在线国际课程一对一试听',
        subTitle: '',
        btnText: '立即预约'
      })
    } else if (formTitleType == 2) {
      this.setState({
        mainTitle: '国际学校资料大礼包',
        subTitle: '（包含课程说明、招生简章、考纲、入学条件、学费报考时间、考试要求、历年真题等）',
        btnText: '立即领取'
      })
    } else if (formTitleType == 3) {
      this.setState({
        mainTitle: '国际学校升学路线规划<br/>入学能力测评、定制择校备考方案',
        subTitle: '',
        btnText: '立即领取'
      })
    } else if (formTitleType == 4) {
      this.setState({
        mainTitle: '国际课程提分大礼包',
        subTitle: '（包含体系介绍、选课指南、提分攻略、报考政策、历年真题、考辅资料、学霸经验等）',
        btnText: '立即领取'
      })
    } else if (formTitleType == 5) {
      this.setState({
        mainTitle: 'NEC 2020全美经济挑战赛',
        subTitle: '',
        btnText: '立即报名'
      })
    } else if (formTitleType == 6) {
      this.setState({
        mainTitle: 'USAD美国学术十项全能竞赛',
        subTitle: '',
        btnText: '立即报名'
      })
    } else {
      this.setState({
        mainTitle: '国际学校资料大礼包',
        subTitle: '（包含课程说明、招生简章、考纲、入学条件、学费报考时间、考试要求、历年真题等）',
        btnText: '立即领取'
      })
    }
    this.setState({
      dialogVisible: 'block'
    })
  }
  // 关闭表单
  closeDialog = () => {
    this.setState({
      dialogVisible: 'none'
    })
  }
  // 提交表单
  submit = () => {
    let listName = this.listNameRef.current.value
    let listPhone = this.listPhoneRef.current.value
    let attendGrade = this.attendGradeRef.current.value
    let remark = this.remarkRef.current.value
    let myreg = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0135678]|19[89]|18[0-9]|14[57])[0-9]{8}$/;
    if (!myreg.test(listPhone)) {
      this.setState({
        tipsShow: 'block'
      })
    } else {
      this.setState({
        tipsShow: 'none'
      })
      let { actKey } = this.props
      let activityId = Activity[actKey].activityId;
      let { buryPoint } = this.props
      // let query = window.location.search
      const params = {
        listName,
        listPhone,
        attendGrade,
        activityId,
        "srcPage": this.browserSource,
        'ipAddr': window.IPdizhi,
        'hiddenRemark': buryPoint ? buryPoint : '',
        'remark': remark ? remark : ''
      }
      this.setState({
        spinning: true
      })
      http.post('submitForm', params).then(res => {
        let msg = res.message
        message.success(msg)
        this.closeDialog()
        this.setState({
          spinning: false
        })
        this.listNameRef.current.value = ''
        this.listPhoneRef.current.value = ''
        this.attendGradeRef.current.value = ''
        this.remarkRef.current.value = ''
      }).catch(err => {
        this.setState({
          spinning: false
        })
      })
    }
  }
  onInput = () => {
    let value = this.listPhoneRef.current.value
    if (value.length > 11) {
      this.listPhoneRef.current.value = value.slice(0, 11)
    }
  }
  // handleSubmit = e => {
  //     e.preventDefault();
  //     this.props.form.validateFields((err, values) => {
  //       if (!err) {
  //         console.log(values)
  //         let { listName, listPhone, attendGrade, remark } = values
  //         let { actKey } = this.props
  //         let activityId = Activity[actKey].activityId;
  //         let {buryPoint} = this.props
  //         // let query = window.location.search
  //         const params = {
  //             'listName': listName ? listName : '',
  //             'listPhone': listPhone ? listPhone : '',
  //             'attendGrade': attendGrade ? attendGrade : '',
  //             activityId,
  //             "srcPage": this.browserSource,
  //             'ipAddr': window.IPdizhi,
  //             'hiddenRemark':buryPoint ? buryPoint : '',
  //             'remark':remark ? remark : ''
  //         }
  //         this.setState({
  //             spinning: true
  //         })
  //         http.post('submitForm', params).then(res => {
  //             let msg = res.message
  //             message.success(msg)
  //             this.closeDialog()
  //             this.setState({
  //                 spinning: false
  //             })
  //             this.props.form.resetFields();
  //         }).catch(err => {
  //             this.setState({
  //                 spinning: false
  //             })
  //         })
  //         debugger
  //       }else{
  //           return
  //       }
  //     });
  // };
  // validatePhone = (rule, value, callback) => {
  //     let myreg=/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0135678]|19[89]|18[0-9]|14[57])[0-9]{8}$/;
  //     if (value && !myreg.test(value)) {
  //         callback('请输入正确的手机号码!');
  //     }
  //     callback();
  // };
  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <section className="popup" style={{ display: this.state.dialogVisible }}>
        <div className="box">
          <Spin spinning={this.state.spinning}></Spin>
          <div className="form-box">
            <div className="close" onClick={this.closeDialog}><img alt="" src={this.image('close')} /></div>
            <div className="form-title">
              <div className="main-title" dangerouslySetInnerHTML={{ __html: this.state.mainTitle }}></div>
              <div className="sub-title" dangerouslySetInnerHTML={{ __html: this.state.subTitle }}></div>
            </div>
            {/* <div className="input-box">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Item>
                                {getFieldDecorator('listName', {
                                    rules: [{ required: false}],
                                })(
                                    <Input placeholder="姓名" />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('listPhone', {
                                    rules: [
                                        { required: true, message: '请输入手机号码！' },
                                        { validator: this.validatePhone },
                                    ],
                                })(
                                    <Input placeholder="手机号" />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('attendGrade', {
                                    rules: [{ required: false }],
                                })(
                                    <Input placeholder="年级" />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('remark', {
                                    rules: [{ required: false}],
                                })(
                                    <Input placeholder="留言" />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    立即免费领取
                                </Button>
                            </Form.Item>
                        </Form>
                        </div> */}
            <div className="input-box">
              <div className="input-wrap"><input type="text" ref={this.listNameRef} placeholder="姓名" /></div>
              <div className="input-wrap"><input type="number" onInput={this.onInput} ref={this.listPhoneRef} placeholder="手机号" /><span style={{ display: this.state.tipsShow }}>请输入正确的手机号码！</span></div>
              <div className="input-wrap"><input type="text" ref={this.attendGradeRef} placeholder="年级" /></div>
              <div className="input-wrap"><input type="text" ref={this.remarkRef} placeholder="留言" /></div>
              <div className="button-wrap"><button onClick={this.submit}>{this.state.btnText}</button></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

// const Poput = Form.create()(PouptForm);
export default Poput;